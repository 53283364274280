import basic from '../basic/ja';
export default {
    ...basic,
    ...{
        meta : {
            title : '無料の AI 画像ジェネレーター: テキストを画像に変換、オンラインで無料で顔を交換',
            keywords : 'フェイススワップ、AI画像ジェネレーター、ビデオフェイススワップ、',
            description : 'テキストプロンプトを使用して AI 生成画像やアートを作成し、高品質の AI 写真とビデオのフェイススワップをオンラインで作成し、Beauty AI 画像ジェネレーターを使用して思いつく限りの画像を作成します。',
        },        
        textimghomeindex : {
            titlebefore : 'AIアート',
            titleafter : 'あなたのアイデアからインスピレーションを得たジェネレータ',
            subtitle : '数秒でテキストから素晴らしい画像を作成します。あなたのインスピレーションを現実にします。',
            buttontext : '今すぐ無料トライアル',
        },
        faceswaphomeindex : {
            titlebefore : '写真とビデオ',
            titleafter : '顔の交換',
            subtitle : 'AI がビデオや写真の顔を交換できるかどうか疑問に思っていませんか? 今すぐ試してみれば、クリックするだけで 1 分以内に素晴らしい顔の交換結果が得られます。',
            buttontext : '今すぐ顔を入れ替える',
        },
        showcasehomeindex : {
            title : 'AI生成画像',
            buttontext : '今すぐ無料トライアル',
            hovertitle : 'このプロンプトを試してください',
        },
        introducehomeindex : {
            item1title : 'AIアート画像をカスタマイズする',
            item1subtitle : 'Beauty AI は、あらゆるスタイルの AI 作品を作成できる AI アート画像ジェネレーターです。インスピレーションを決め、プロンプトで説明し、AI 画像に変換します。アート作品を作成するときは、作品があなたのものであることを忘れないでください。',
            item1buttontext : '今すぐ無料トライアル',
            item2buttontext : '今すぐ無料トライアル',
        },        
    },
};