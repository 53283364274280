import basic from '../basic/en';
export default {
    ...basic,
    ...{        
        meta: {
            title: 'Free AI Image Generator: Text to Image Online; Face Swap Online Free',
            keywords: 'Face Swap,AI Image Generator,Video Face Swap,',
            description: 'Produce AI-generated images and art with a text prompt, High qualiy AI Photo&Videos Face Swap online, Create any image you can dream up with Beauty AI image generator.',
        },
        textimghomeindex: {
            titlebefore: 'AI Art',
            titleafter: 'Generator inspired by your idea',
            subtitle: 'Create amazing image from text in seconds. Make your inspiration into reality.',
            buttontext: 'Free trial now',
        },
        faceswaphomeindex: {
            titlebefore: 'Photo&Video',
            titleafter: 'Face Swap',
            subtitle: 'Wondering whether AI can swap face in video and photo? Try it now and you will get exquisite faceswap result in a minute by a click.',
            buttontext: 'Face swap now',
        },
        showcasehomeindex: {
            title: 'AI Generated Images',
            buttontext: 'Free trial now',
            hovertitle: 'Try this prompt',
        },
        introducehomeindex: {
            item1title: 'Customize Your AI Art Images',
            item1subtitle: 'Beauty AI is an AI Art Image Generator that allows you create AI creations in all styles. Decide to your inspiration, describe it with prompt and turn it into AI images. Remember your creations belong to you as you make your art creation.',
            item1buttontext: 'Free trial now',
            item2buttontext: 'Free trial now',
        },
    },
};