import basic from '../basic/pt';
export default {
    ...basic,
    ...{
        meta : {
            title : 'Gerador de imagens AI gratuito: texto para imagem online; Troca de rosto online grátis',
            keywords : 'Troca de rosto, Gerador de imagem AI, Troca de rosto de vídeo,',
            description : 'Produza imagens e arte geradas por IA com um prompt de texto, troca de rosto de fotos e vídeos com IA de alta qualidade on-line, crie qualquer imagem que você possa imaginar com o gerador de imagens Beauty AI.',
        },        
        textimghomeindex : {
            titlebefore : 'Arte de IA',
            titleafter : 'Gerador inspirado na sua ideia',
            subtitle : 'Crie imagens incríveis a partir de texto em segundos. Transforme sua inspiração em realidade.',
            buttontext : 'Teste grátis agora',
        },
        faceswaphomeindex : {
            titlebefore : 'Foto e vídeo',
            titleafter : 'trocas de rosto',
            subtitle : 'Quer saber se a IA pode trocar o rosto em vídeo e foto? Experimente agora e você obterá resultados excelentes de troca de rosto em um minuto com um clique.',
            buttontext : 'Troca de rosto agora',
        },
        showcasehomeindex : {
            title : 'Imagens geradas por IA',
            buttontext : 'Teste grátis agora',
            hovertitle : 'Tente este prompt',
        },
        introducehomeindex : {
            item1title : 'Personalize suas imagens de arte AI',
            item1subtitle : 'Beauty AI é um gerador de imagens de arte de IA que permite que você crie criações de IA em todos os estilos. Decida sua inspiração, descreva-a com prompt e transforme-a em imagens de IA. Lembre-se de que suas criações pertencem a você enquanto você faz sua criação artística.',
            item1buttontext : 'Teste grátis agora',
            item2buttontext : 'Teste grátis agora',
        
        },        
    },
};