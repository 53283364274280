import basic from '../basic/fr';
export default {
    ...basic,
    ...{
        meta : {
            title : 'Générateur d\'images AI gratuit : texte en image en ligne ; Échange de visage en ligne gratuit',
            keywords : 'Échange de visage, générateur d\'images AI, échange de visage vidéo,',
            description : 'Produisez des images et des œuvres d\'art générées par l\'IA avec une invite de texte, des photos et des vidéos AI de haute qualité en ligne, créez n\'importe quelle image que vous pouvez imaginer avec le générateur d\'images Beauty AI.',
        },        
        textimghomeindex : {
            titlebefore : 'Art de l\'IA',
            titleafter : 'Générateur inspiré de votre idée',
            subtitle : 'Créez une image étonnante à partir de texte en quelques secondes. Transformez votre inspiration en réalité.',
            buttontext : 'Essai gratuit maintenant',
        },
        faceswaphomeindex : {
            titlebefore : 'Photo et vidéo',
            titleafter : 'échanges de visages',
            subtitle : 'Vous vous demandez si l’IA peut échanger le visage en vidéo et en photo ? Essayez-le maintenant et vous obtiendrez un résultat d\'échange de visage exquis en une minute par un clic.',
            buttontext : 'Échange de visage maintenant',
        },
        showcasehomeindex : {
            title : 'Images générées par l\'IA',
            buttontext : 'Essai gratuit maintenant',
            hovertitle : 'Essayez cette invite',
        },
        introducehomeindex : {
            item1title : 'Personnalisez vos images artistiques IA',
            item1subtitle : 'Beauty AI est un générateur d\'images d\'art IA qui vous permet de créer des créations IA dans tous les styles. Choisissez votre inspiration, décrivez-la avec invite et transformez-la en images IA. N\'oubliez pas que vos créations vous appartiennent lorsque vous réalisez votre création artistique.',
            item1buttontext : 'Essai gratuit maintenant',
            item2buttontext : 'Essai gratuit maintenant',
        },        
    },
};