<template>
    <TopNavigatorBar ref="topNavBarDOMTag" :highlightIndex="-1"/>
    <div class="v-space1"></div>
    <TextImgHomeIndex/>
    <!-- <div class="v-space2"></div> -->
    <FaceSwapHomeIndex/>
    <div class="v-space3"></div>
    <ShowcaseHomeIndex/>
    <div class="v-space3"></div>
    <IntroduceItem @action="introduceAction"/>
    <BottomBar/>
  </template>
  
  <script setup>
  import TopNavigatorBar from '@/components/TopNavigatorBar.vue';
  import BottomBar from '@/components/BottomBar.vue';
  import TextImgHomeIndex from '@/components/home/TextImgHomeIndex.vue';
  import FaceSwapHomeIndex from '@/components/home/FaceSwapHomeIndex.vue';
  import ShowcaseHomeIndex from '@/components/home/ShowcaseHomeIndex.vue';
  import IntroduceItem from '@/components/home/IntroduceHomeIndex.vue';
  import { ref, onMounted, getCurrentInstance, provide } from 'vue';
  import { useTxtToImg } from '@/stores/useTxtToImg';
  
  const { $api, $utility, $analytic, i18n, $jumpOtherPage } = getCurrentInstance().appContext.config.globalProperties;
  const topNavBarDOMTag = ref(null);
  useTxtToImg().init();
  
  const sArg = $utility.getQueryVariable('s');
  if (sArg) {
    window.localStorage.setItem('affiliateChildKey', sArg);
    $api.affiliateVisit(sArg);
  }
  
  onMounted(() => {
    document
      .querySelector('title')
      .innerText = i18n.global.t('meta.title');
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', i18n.global.t('meta.description'));
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute('content', i18n.global.t('meta.keywords'));
  
    $analytic.page = 'meming';
  });

  const introduceAction = () => {
    $analytic.log('desc_freetrial_tick');
    $jumpOtherPage('/free-ai-image-generator', true, true);
  };
  
  provide('toSignUpHandle', () => {
    topNavBarDOMTag.value.signinClick();
  });
  
  </script>
  
  <style lang="scss">
  body {
    background-color: #070A14;
    background-position: top;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0px;
    overscroll-behavior-y: none;
    background-image: none !important;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  .v-space1 {
    height: 70px;
    background-color: #0D0D0D;
  }
  .v-space2 {
    height: 87px;
    background-color: #0D0D0D;
  }
  .v-space3 {
    height: 100px;
  }
  @media (max-width:999px) {
    .v-space1 {
      height: 56px;
      background-color: #0D0D0D;
    }
    .v-space2 {
      height: 45px;
    }
    .v-space3 {
      height: 40px;
    }
  }
  </style>
  