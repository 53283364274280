import basic from '../basic/es';
export default {
    ...basic,
    ...{
        meta : {
            title : 'Generador de imágenes AI gratuito: texto a imagen en línea; Intercambio de caras en línea gratis',
            keywords : 'Intercambio de caras, Generador de imágenes AI, Intercambio de caras de vídeo,',
            description : 'Produzca imágenes y arte generados por IA con un mensaje de texto, intercambio de caras de fotos y videos con IA de alta calidad en línea, cree cualquier imagen que pueda imaginar con el generador de imágenes Beauty AI.',
        },        
        textimghomeindex : {
            titlebefore : 'Arte de IA',
            titleafter : 'Generador inspirado en tu idea',
            subtitle : 'Crea imágenes asombrosas a partir de texto en segundos. Haz realidad tu inspiración.',
            buttontext : 'Prueba gratuita ahora',
        },
        faceswaphomeindex : {
            titlebefore : 'Foto y vídeo',
            titleafter : 'intercambios de caras',
            subtitle : '¿Se pregunta si la IA puede intercambiar la cara en video y foto? Pruébelo ahora y obtendrá un resultado de intercambio de caras exquisito en un minuto con un clic.',
            buttontext : 'Cambia de cara ahora',
        },
        showcasehomeindex : {
            title : 'Imágenes generadas por IA',
            buttontext : 'Prueba gratuita ahora',
            hovertitle : 'Pruebe este mensaje',
        },
        introducehomeindex : {
            item1title : 'Personaliza tus imágenes artísticas de IA',
            item1subtitle : 'Beauty AI es un generador de imágenes artísticas con IA que te permite crear creaciones con IA en todos los estilos. Decida su inspiración, descríbala rápidamente y conviértala en imágenes de IA. Recuerde que sus creaciones le pertenecen a usted mientras realiza su creación artística.',
            item1buttontext : 'Prueba gratuita ahora',
            item2buttontext : 'Prueba gratuita ahora',
        },        
    },
};